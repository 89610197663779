<template>
  <div v-if="active == 1">
   <div style="textAlign:center" v-show="show">
        <p>正在导入</p>
        <el-progress :percentage="100" :format="format" :indeterminate="true" />
      </div>
    <div class="theSteps">
      <el-steps :active="active" finish-status="success">
        <el-step title="上传导入数据" icon="el-icon-upload2" />
        <el-step title="数据预览" icon="el-icon-s-order" />
        <el-step title="数据导入完成" icon="el-icon-s-claim" />
      </el-steps>
    </div>
    <lida-table
      :data="tableList"
      border
      :pagination="false"
      ref="table"
    >
      <lida-table-column fixed prop="brandName" label="品牌" />
      <lida-table-column prop="productName" label="商品名称" />
      <lida-table-column prop="articleNo" label="订货号" />
      <lida-table-column prop="barCode" label="型号" />
      <lida-table-column label="数量" prop="quantity">
        <template #default="scope">
          <el-input-number v-model="scope.row.quantity" :min="1" />
        </template>
      </lida-table-column>
      <lida-table-column label="操作" fixed="right">
        <template #default="scope">
          <el-button size="mini" @click="deleteRow(scope)" type="text"
            >移除</el-button
          >
        </template>
      </lida-table-column>
    </lida-table>
    <div style="margin: 5% 0;display: flex;justify-content: center;">
      <el-button type="primary" @click="submitForm">确定导入</el-button>
      <el-button @click="resetForm">上一步</el-button>
       <el-button
              type="text"
              v-show="content"
              icon="el-icon-download"
              @click="exportFu2"
              accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              >下载失败列表</el-button
            >
    </div>
  </div>
  <lastUploadStock v-if="active == 2" :fileContent="fileContent"/>
</template>
<script>
import { mapActions } from "vuex";
import lastUploadStock from "./lastUploadStock";
export default {
  // props:["tableList"],
  props: {
    tableArr: Array, //定义传值的类型<br>
    fileContent:Object
  },
  data() {
    return {
      active: 1,
      tableList: [],
      content:'',
      show:''
    };
  },
  components: {
    // secondUploadStock,
    lastUploadStock,
  },
  methods: {
    next() {
      if (this.active++ > 2) this.active = 0;
    },
    ...mapActions("stockModule", [
      "stockImport", //库房导入
    ]),
    submitForm() {
      this.show='true'
      let arr= this.tableList.map((v) => {
      return {
        rowNum: v.rowNum,
        errorMsg: v.errorMsg,
        brandName: v.brandName,
        productName: v.productName,
        articleNo: v.articleNo,
		barCode: v.barCode,
        quantity: String(v.quantity),
        skuId: v.skuId,
        id: v.id,
        customerId: v.customerId,
      };
    });
      this.stockImport({ importData: arr }).then((res) => {
        // console.log(res);
        if (res.data.code == "0") {
          this.show=''
          this.$message.success("导入成功");
          // this.$router.push({ path: "lastUploadStock" });
          this.next();
        }
      });
    },
    resetForm() {
      // this.$router.push({ path: "uploadStock" });
      if (window.history.length > 1) {
        this.$router.back();
      } else {
        this.$router.push("index");
      }
    },
    deleteRow(obj) {
      //删除行
      var data = JSON.parse(JSON.stringify(this.tableList));
      data.splice(obj.$index, 1);
      this.tableList = [...data];
      // console.log(obj);
    },
      exportFu2() {
        const a = document.createElement("a");
        a.style.display = "none";
        a.download = this.fileContent.failFileName;
        a.href = `${request.defaults.baseURL}/excel/${this.fileContent.failFileName}`;
        document.body.appendChild(a);
        a.click(); // 自动触发点击a标签的click事件
        document.body.removeChild(a);
    },
  },
  mounted() {
    this.tableList = this.tableArr.map((v) => {
      return {
        rowNum: v.rowNum,
        errorMsg: v.errorMsg,
        brandName: v.brandName,
        productName: v.productName,
        articleNo: v.articleNo,
		barCode: v.barCode,
        quantity: Number(v.quantity),
        skuId: v.skuId,
        id: v.id,
        customerId: v.customerId,
      };
    });
    if (this.fileContent.failQty!=0) {
      this.content='true'
    }
    // console.log(this.fileContent);
  },
};
</script>
<style scoped>
.theSteps {
  width: 700px;
  margin: 50px auto;
}
/* 进行中状态：圈线 */
.theSteps >>> .el-step__head.is-process {
  color: #409eff;
  border-color: #409eff;
}
/* 进行中状态：圈内 */
.theSteps >>> .el-step__head.is-process > .el-step__icon {
  background: #fff;
  color: #409eff;
}
/* 进行中状态：title（文字） */
.theSteps >>> .el-step__title.is-process {
  color: #409eff;
}

/* 完成状态：圈线 */
.theSteps >>> .el-step__head.is-success {
  color: #409eff;
  border-color: #409eff;
}
/* 完成状态：title（文字） */
.theSteps >>> .el-step__title.is-success {
  color: #409eff;
}
</style>
